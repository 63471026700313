const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://nveceleadershipseries.com/",
		"gaTrackingId": null
	},
	"header": {
		"logo": "https://finportal.s3.amazonaws.com/nevada_ece_articulate/NevadaECE_primary_White-v.png",
		"logoLink": "https://nveceleadershipseries.com/",
		"title": "Nevada ECE Leadership Series",
		"githubUrl": "https://github.com/nveceleaders/learning-site",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
			"/training",
			"/literacy",
    	"/math"
		],
		"links": [
			{ "text": "Return to landing page", "link": "https://nveceleadershipseries.com/"},
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "Nevada ECE Leadership Series",
		"description": "Leadership series for Nevada early childhood education",
		"ogImage": null,
		"docsLocation": "https://github.com/nveceleaders/learning-site/tree/master/static/img",
		"favicon": "https://finportal.s3.amazonaws.com/nevada_ece_articulate/NevadaECE_primary_White-v.png"
	},
};

module.exports = config;
